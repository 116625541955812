<template>
<div class="wrapper"   v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <div class="flex items-center gap-3">
            <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.push('/objects')">
                <i class="el-icon-arrow-left"></i>
            </button>
            <h1>{{ $store.state.me.organization? `"${$store.state.me.organization.name_cyr}" - ${$t('оbjectsi')}` : $t('all_object_list')  }} </h1>
        </div>
    </div> 
    <div class="wp-body" v-if="knumber==6">
       
        <div class="">
            <div class="input-section grid gap-4">
                <el-form :model="obj" :rules="rules" ref="obj" class="Form">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <label class="item">
                                <p> {{$t('object_cadasral_number')}} </p>
                                <el-form-item prop="cadastral_number"> 
                                    <el-input disabled  v-model="obj.cadastral_number" v-mask="'##:##:##:##:##:####'" :placeholder="`${$t('example')}: 10:01:01:01:01:1111`" clearable />
                                </el-form-item>
                            </label>
                            <label v-if="NewCadasterInfo.cad_number_old" class="item">
                                <p>{{$t('object_cadasral_number')}} <span style="color: green; font-weight: 700;">(Eski)</span></p>
                                <el-form-item> 
                                    <el-input disabled  v-model="NewCadasterInfo.cad_number_old" v-mask="'##:##:##:##:##:####'" :placeholder="`${$t('example')}: 10:01:01:01:01:1111`" clearable />
                                </el-form-item>
                            </label>
                            <!-- <label class="item">
                                <p>Объект номи</p>
                                <el-form-item {{  item.name_cyr  }}>
                                    <el-input :disabled="disabled" v-model="obj.name_cyr" clearable />
                                </el-form-item>
                            </label> -->
                            <label class="item" v-if="role == 'ministry'">
                                <p> {{$t('object_owner')}} </p>
                                <el-form-item prop="organization">
                                    <el-autocomplete :disabled="disabled" name="organization" class="w-full" valueKey="name_cyr" v-model="state1" :fetch-suggestions="querySearch" :placeholder="$t('object_owner')" @select="handleSelect"></el-autocomplete>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('object_region')}}</p>
                                <el-form-item prop="region">
                                    <el-select disabled v-model="obj.region"  name="object_region" :placeholder="$t('object_region')" class="w-full" v-if="parseInt(obj.region)<16">
                                        <el-option v-for="item in dictionary.region" :key="item.id" :label="item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-select disabled v-model="obj.region" name="object_region" class="w-full" :placeholder="$t('object_region')" v-else>
                                        <el-option v-for="item in dictionary.region" :key="item.shared_id" :label="item.name_cyr" :value="item.shared_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('object_disrtict')}} </p>
                                <el-form-item prop="district">
                                    <el-input disabled v-if="cadastor_data.district" name="district" :placeholder="$t('object_disrtict')" v-model="cadastor_data.district"></el-input>
                                    <el-select disabled :loading="loading" name="district" v-model="obj.district" class="w-full" v-else>
                                        <el-option v-for="item in current_district" :key="item.shared_id" :label="item.name_cyr" :value="item.shared_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('object_neigborhood')}}</p>
                                <el-input name="object_neigborhood" disabled v-model="obj.neighborhood" :placeholder="$t('object_neigborhood')" clearable />
                            </label>
                        </el-col>
                        <el-col :span="8">

                            <label class="item">
                                <p> {{$t('obj_address')}} </p>
                                <el-form-item prop="address">
                                    <el-input name="address" :disabled="!error.adress" v-model="obj.address" :placeholder="$t('obj_address')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('obj_year_construction')}}</p>
                                <el-form-item prop="year_construction">
                                    <el-date-picker name="year_construction" :disabled="error.date || disabled" :placeholder="$t('obj_year_construction')" style="width: 100%" type="year" v-model="obj.year_construction" value-format="yyyy">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                            <label class="item w-full">
                                <p> {{$t('obj_date_of_passport')}} </p>
                                <el-form-item prop="date_of_passport">
                                    <el-date-picker name="date_of_passport" disabled v-model="obj.date_of_passport" style="width: 100%" :placeholder="$t('get_now_date')" type="date" value-format="dd.MM.yyyy">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                            <div class="item" :class="ErrorStatus==2 ? 'erorr-status':''">
                                <p> {{$t('obj_historical_status')}} </p>
                                <template>
                                    <el-radio name="obj_historical_status_on" :disabled="disabled" v-model="obj.historical_status" class="prim" :label="true" border> {{$t('obj_historical_status_on')}} </el-radio>
                                    <el-radio name="obj_historical_status_off" :disabled="disabled" v-model="obj.historical_status" class="prim" :label="false" border>{{$t('obj_historical_status_off')}}</el-radio>
                                </template>
                                <p v-if="ErrorStatus==2" style="color:red;"> {{$t('choose')}} </p>
                            </div>
                        </el-col>
                        <el-col :span="8">

                            <label class="item">
                                <p>{{$t('obj_number_buildings')}}</p>
                                <el-form-item prop="number_buildings">
                                    <el-select name="number_buildings"  disabled v-model="obj.number_buildings" :placeholder="$t('obj_number_buildings')" type="number" class="w-full" allow-create filterable>
                                        <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('phone')}} </p>
                                <el-input name="phone" v-mask="'+998 (##) ###-##-##'" :disabled="disabled" v-model="obj.phone" placeholder="+998 (__) ___-__-__" clearable />

                            </label>

                            <label class="item">
                                <p> {{$t('obj_geolocation')}} </p>
                                <el-form-item prop="geolocation">
                                    <div class="flex align-top">
                                        <el-input @change.native="panToEntered()" name="obj_geolocation" disabled style="width: 100%" v-model="coor" />
                                    </div>
                                    <div style="position: relative; overflow: hidden; margin-top: 10px;" class="flex justify-end">
                                        <el-tooltip style="margin:0" effect="dark" :content=" disabled ? $t('show_geo'): $t('change_geo')" placement="bottom">
                                            <el-button class="primary-btn" style="border-radius:5px; font-size:14px; font-size:14px;  padding:15px 20px;" @click="EditMap(obj.district)">
                                                <i class="el-icon-location-outline mr-1"></i>
                                                <span v-if="disabled"> {{$t('show_geo')}} </span>
                                                <span v-else> {{$t('change_geo')}} </span>
                                            </el-button>
                                        </el-tooltip>
                                    </div>
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col v-if="!['org_admin', 'ministry_admin'].includes(role)" :md="24">
                            <div v-if="disabled" class="flex items-center justify-end gap-1 mt-10">
                                    <button v-if="!isCreatePage" type="button" class="button dark-yellow" @click="$router.push({name: 'building-list-one', params: { id: $route.params.id, org:state1 }, })"   ><i class="el-icon-document mr-1"></i> {{$t('building_list')}} </button>
                                    <button v-if="!isCreatePage && ['operator', 'ministry'].includes(role)" type="button" class="button purple"  @click="$router.push({name: 'add-build', params: { id: $route.params.id, obj:$route.params.id, cadastral_number:obj.cadastral_number, geo:obj.geolocation },})"><i class="el-icon-plus mr-1"></i> {{$t('add_building')}} </button>
                                    <button v-if="isInfoPage && disabled"  class="button dark-blue" type="button" @click="disabled = !disabled"><i class="el-icon-edit mr-1"></i> {{ $t('change') }} </button>
                                </div>
                                <div v-else class="flex items-center gap-1  justify-end mt-10">
                                    <button  class="button dark-blue" type="button" @click="save()" ><i class="el-icon-document-checked mr-1"></i> {{$t('save')}}</button>
                                </div> 
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>

    <div class="fixed-item" v-else v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="fixed-cover"></div>

        <div class="about-knumber">
            <button class="mt-3">
                <svg version="1.1" fill="#fff" id="Capa_1" width="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                        <g>
                            <path d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256

C512,114.497,397.492,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216

c119.393,0,216,96.615,216,216C472,375.393,375.384,472,256,472z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M256,214.33c-11.046,0-20,8.954-20,20v128.793c0,11.046,8.954,20,20,20s20-8.955,20-20.001V234.33

C276,223.284,267.046,214.33,256,214.33z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <circle cx="256" cy="162.84" r="27" />
                        </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </button>
            <button class="mt-3">
                <svg version="1.1" id="Capa_1" width="30" fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                        <g>
                            <g>
                                <circle cx="256" cy="378.5" r="25" />
                                <path d="M256,0C114.516,0,0,114.497,0,256c0,141.484,114.497,256,256,256c141.484,0,256-114.497,256-256

C512,114.516,397.503,0,256,0z M256,472c-119.377,0-216-96.607-216-216c0-119.377,96.607-216,216-216

c119.377,0,216,96.607,216,216C472,375.377,375.393,472,256,472z" />
                                <path d="M256,128.5c-44.112,0-80,35.888-80,80c0,11.046,8.954,20,20,20s20-8.954,20-20c0-22.056,17.944-40,40-40

c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40c-11.046,0-20,8.954-20,20v50c0,11.046,8.954,20,20,20

c11.046,0,20-8.954,20-20v-32.531c34.466-8.903,60-40.26,60-77.469C336,164.388,300.112,128.5,256,128.5z" />
                            </g>
                        </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="pr kadaster-item">
            <!-- <p style="color:#fff">{{a}}</p>  --> 
            <div v-if="NewCadasterInfo.is_changed"  class="warningk">
                <p> 
                    Ушбу объект кадастр рақами янгиланган <br>
                    Янги кадастр рақами: <b >{{ NewCadasterInfo.cad_num }}</b>
                </p>
            </div>
            <div class="erorrsk" v-if="knumber==2">
                <p> {{$t('no_cadaster_number')}} </p>
            </div>
            <div class="uncompletek" v-if="knumber==3">
                <p> {{$t('uncomplate_cadaster_data')}} </p>
            </div>
            <div class="succesk" v-if="knumber==4">
                <p> {{$t('cadaster_number_succes_find')}} </p>
            </div>
            <div class="erorrsk" v-if="knumber==5">
                <p> {{$t('have_cadaster_number_obj')}} </p>
            </div>
            <div class="erorrsk" v-if="knumber==7">
                <p> {{$t('error_ser')}} </p>
            </div>
            <div class="erorrsk" v-if="knumber==8">
                <p> {{$t('error_cadaster_ser')}} </p>
            </div>
            <div class="erorrsk" v-if="knumber==9">
                <p> {{$t('only_add_flat')}} </p>
            </div>
            <div class="erorrsk" v-if="knumber==10">
                <p> {{$t('object_nobuilding')}} </p>
            </div>

            <div class="pb-1">
                <span style="color:#fff; font-size:16px;"> {{$t('object_cadasral_number')}} </span>
            </div>
            <div class="pb-3">
                <el-input style="min-width:450px;"  name="cadastral_number" class="k-input" :class="knumber==1 || knumber==5 || knumber==8 || knumber==9 || knumber==2 ? 'error': knumber==3 ? 'connecting': knumber==4 ? 'succes': ''" @input="CheckKadastor()" v-model="obj.cadastral_number" v-mask="'##:##:##:##:##:####'" placeholder="Мисол: 10:01:01:01:01:1111" clearable />
                <p v-if="knumber==1" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;"> {{$t('uncomplate_cadaster_number')}} </p>
                <p v-if="knumber==2" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;"> {{$t('noregister_cadaster_number')}} </p>
                <p v-if="knumber==3" style="color:rgb(101, 236, 101); padding-top:5px; font-size:16px;"> {{$t('cadaster_find_uncomplate_data')}} </p>
                <p v-if="knumber==4" style="color:rgb(101, 236, 101); padding-top:5px; font-size:16px;"> {{$t('cadaster_number_find_succes')}} </p>
                <p v-if="knumber==5" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;"> {{$t('cadaster_number_have_in')}} </p>
                <p v-if="knumber==7" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;"> {{$t('error_with_server')}} </p>
                <p v-if="knumber==8" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;"> {{$t('error_with_cadaster_server')}} </p>
                <p v-if="knumber==9" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">{{$t('only_add_flat')}}</p>
                <p v-if="knumber==10" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;"> {{$t('object_nobuilding')}} </p>

            </div>
            <div class=" flex justify-end">
                <button class="kt-button" style="background:#004787; color:#fff;" @click="$router.push({path:'/objects'})"> {{$t('cancel')}} </button>
                <button class="kt-button ml-1 bg10" v-if="knumber==3" style=" color:#fff;" @click="GoAdd()"><i class="el-icon-document mr-1"></i> {{$t('continue_add')}} </button>
                <button class="kt-button ml-1 bg10" v-if="knumber==4" style=" color:#fff;" @click="GoAdd()"> <i class="el-icon-document-checked mr-1"></i> {{$t('continue_add')}}</button>
            </div>
        </div>
 
    </div>
    <div  v-if="modal"  class="modal fs-14" :class="modal?'active':''">
        <div class="modal-cover"></div>
        <div class="modal-item padding-map" style="width:95%; height:95%;">
            <div class="flex justify-between modal-absolute-header">
                <p class="modal-title "> {{disabled? `${$t('show_coor')}:`:`${$t('change_coor')}:`}}</p>
                <button style="font-size:16px; " @click="modal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
            </div>
            <div class="w-full map-item h-full">
                <yandex-map @click="clicked" :coords="coords" style="height:100%; width: 100%" :zoom="14" :controls="['zoomControl']">
                    <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
                </yandex-map>
            </div>
            <div class="flex w-full justify-between modal-bottom fw-w">
                <div class="modal-inputes flex items-center">
                    <p class="flex items-center" style="font-size:16px;"><b class="mr-2"> {{$t('coor')}} :</b> {{coords.join()}} </p>
                </div>
                <div class="modal-inputes">
                    <button @click="modal=false"   class="responsive-btn"> <i class="el-icon-close mr-2"></i> {{$t('close')}} </button>
                    <button v-if="!disabled" class="ml-2 responsive-btn" style="padding:15px 15px; background:green;" @click="SaveEditMap()"> <i class="el-icon-document-checked mr-2"></i> {{$t('save')}} </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import mapState from "vuex";
import districts from '@/assets/files/districts.json'
export default {
    data() {
        return {
            ab: 'ss',
            districts: districts,
            modal: false,
            knumber: 0,
            ErrorStatus: 0,
            error: {},
            state1: this.$route.params.org ? this.$route.params.org : '',
            isEditPage: false,
            cadastor_data: {},
            isInfoPage: false,
            isCreatePage: false,
            disabled: false,
            loading: true,
            current_district: [],
            orgList: [],
            search: "",
            orgList2: [],
            coords: [41.31629324503914, 69.26717199864542],
            coor: '',

            obj: {
                // name_uz: "",
                // name_ru: "",
                // name_cyr: "",
                cadastral_number: "",
                year_construction: "",
                region: null,
                district: null,
                address: "",
                // date_of_passport: null,
                phone: "",
                number_buildings: null,
                historical_status: "",
                geolocation: this.Coordes,
                organization: "",
                neighborhood_id: "",
                neighborhood: '',
            },
            rules: {
                organization: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
                cadastral_number: [{
                        required: true,
                        message:this.$t('please_add_cadastrel_number'),
                        trigger: "change",
                    },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                year_construction: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                region: [{
                    required: true,
                    message: this.$t('choose_region'),
                    trigger: "change"
                }, ],
                district: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
                address: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
                date_of_passport: [{
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
                number_buildings: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
                historical_status: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
                geolocation: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }, ],
            },
            NewCadasterInfo:{
                cad_num:'',
                is_changed:'',
                cad_number_old:''
            },
            check_cad_number:''
        };
    },
    mounted() {
        if (this.$route.name === "obj-info") {
            this.disabled = true;
            this.isInfoPage = true;
        } else if (this.$route.name === "obj-update") {
            this.isEditPage = true;
        }

        if (this.$route.name !== "obj-create") {
            this.knumber = 6
            axios
                .get(`/building/generalinformation/${this.$route.params.id}/`)
                .then((response) => { 
                    console.log(response)
                    if(response.data.cadastral_number_old){
                        this.NewCadasterInfo.cad_number_old=response.data.cadastral_number_old
                    }
                    this.obj = response.data;
                    this.coor = this.obj.geolocation.join()
                    this.initialCoords = response.data.geolocation;
                    this.coords = response.data.geolocation;
                    this.obj.year_construction = String(response.data.year_construction);
                    // this.obj.geolocation = JSON.parse(response.data.geolocation);
                })
                .finally(() => {
                    this.loading = false;
                })
        } else {
            this.isCreatePage = true;
            this.loading = false;
        }
    },
    computed: {
        dictionary() {
            return this.$store.state.dictionary;
        },
        Coordes() {
            return this.$store.state.Coord;
        },
        me() {
            return this.$store.state.me;
        },
        role(){
            return this.$store.state.role;
        }
    },
    watch: {

        'obj.historical_status'() {
            if (this.obj.historical_status != "") {
                this.ErrorStatus = 0
            }
        }
    },
    methods: {
        // Keremas
        querySearch(queryString, cb) {
            axios.get(`/organization/?limit=200&search=${queryString}`)
                .then((response) => {
                    cb(response.data.results)
                })
        },
        createFilter() {
            return (link) => {
                return link.name_cyr
            };
        },
        handleSelect(item) {
            this.obj.organization = item.id 
        },

        //   Keremas
        goBack() {
            // window.history.back();
            this.$router.go(-1)
        },
        regionChanged() {
            this.obj.district = "";
            this.current_district = [];
            this.getDistrict();
        },

        EditMap(id) {
            this.modal = true
            if (this.coor) {
                this.coords = this.coor.split(',')
            } else {

                this.coords = this.districts[`${id}`].coor
            }

        },
        SaveEditMap() {
            this.modal = false
            this.coor = this.coords.join()
            this.obj.geolocation = this.coords;
        },
        clicked(e) {
            if (this.disabled) {
                this.coords = e.get("coords");
            }
        },
        save() {

            // this.obj.geolocation = this.obj.geolocation ? JSON.stringify(this.obj.geolocation) : this.obj.geolocation;
            this.$refs.obj.validate((valid) => {
                if (valid) {

                    if (this.obj.historical_status !== "") {
                        this.loading = true;
                        if (this.isInfoPage || this.isEditPage) {
                            axios
                                .patch(
                                    `/building/generalinformation/${this.$route.params.id}/`,
                                    this.obj
                                )
                                .then((response) => {
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: this.$t('data_saved'),
                                        timer: 3000
                                    })
                                    this.goBack();
                                })
                                .catch((error) => {
                                    this.$swal.fire({
                                        icon: 'error',
                                        title: this.$t('error_save'),
                                        timer: 3000
                                    })
                                })
                                .finally(() => {
                                    this.loading = false;
                                });
                        } else {
                            axios
                                .post("/building/generalinformation/", this.obj)
                                .then((response) => {
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: this.$t('data_saved'),
                                        timer: 3000
                                    })

                                    this.goBack();
                                })
                                .catch((error) => {
                                    this.$swal.fire({
                                        icon: 'error',
                                        title: this.$t('error_save'),
                                        timer: 3000
                                    })
                                })
                                .finally(() => {
                                    this.loading = false;
                                });
                        }
                        this.ErrorStatus = 1
                    } else {
                        this.ErrorStatus = 2
                    }

                }
            });
        },
        SearchAll(queryString, cb) {
            var a = "";
            axios.get(`/organization/?search=${queryString}`)
                .then((response) => {
                    a = response.data.results;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        cb(a);
                    }, 3000 * Math.random());
                })

        },
        panToFirst() {
            this.coords = this.initialCoords;
        },
        panToEntered(e) {
            // this.coords = this.obj.geolocation.split(",");
        },
        clicked(e) {
            if (!this.disabled) this.coords = e.get("coords");
        },
        CheckKadastor() {
            if (this.obj.cadastral_number.length < 19) {
                this.knumber = 1
            }
            if (this.obj.cadastral_number.length == 19) {
                this.loading = true
                var data = {
                    type: "cadastr",
                    cad_num: this.obj.cadastral_number,
                }
                this.erors = []
                axios.get(`/building/cadastral_number/${this.obj.cadastral_number}/`)
                    .then(response => { 
                        if (response.data.result == 1) {
                            this.loading = false
                            this.knumber = 5
                        } 
                        else if(response.data.result == 2){
                            this.loading = false
                            this.knumber = 10
                        }
                        else {
                            axios.post('/dictionary/insert/cadastr/', data)
                                .then((res) => {
                                    this.a = res.data.result   
                                    if(res.data.is_changed){
                                        this.NewCadasterInfo.is_changed=res.data.is_changed
                                        this.NewCadasterInfo.cad_num=res.data.cad_number 
                                        this.NewCadasterInfo.cad_number_old=res.data.cad_number_old 
                                    }
                                    
                                    if (res.data.result == 0) {
                                        this.knumber = 2
                                        this.loading = false
                                        this.error.message = res.data.message
                                    } 
                                    else if (res.data.result == 2) {
                                        this.knumber = 2
                                        this.loading = false
                                        this.error.message = res.data.message
                                    } 
                                    else if(res.data.result == 3){
                                        this.knumber = 10
                                        this.loading = false
                                    }
                                    else {
                                        this.cadastor_data = res.data
                                        // Street
                                        if (res.data.address) {
                                            var address = res.data.address.split(",")
                                            if (address.length > 3) {
                                                this.obj.address = address[0] + address[1]

                                            } else {
                                                address = res.data.address.split(",")
                                                if (address.length > 2) {
                                                    this.obj.address = address[0] + address[1]
                                                } else {
                                                    this.obj.address = res.data.address
                                                }
                                            }
                                            this.error.adress = false

                                        } else {
                                            this.error.adress = true
                                        }
                                        // Region
                                        this.obj.region = res.data.region_id
                                        // District
                                        if (this.obj.region) {
                                            axios.get(`/dictionary/district/?region__shared_id=${this.obj.region}`)
                                                .then((response) => {
                                                    this.current_district = response.data;
                                                    this.obj.district = res.data.district_id
                                                })
                                            this.error.region = false
                                        } else {
                                            this.error.region = false
                                        }
                                        // neighbordhood
                                        if (res.data.neighborhood_id) {
                                            this.obj.neighborhood_id = res.data.neighborhood_id
                                            this.obj.neighborhood = res.data.neighborhood
                                        }
                                        // number_buildings
                                        if (res.data.objects_count) {
                                            this.obj.number_buildings = parseInt(res.data.objects_count)
                                        } else {
                                            this.obj.number_buildings = 0
                                        }

                                        // date building
                                        if (res.data.year) {
                                            var date = res.data.year.split(".")
                                            if (date.length > 1) {
                                                this.obj.year_construction = date[2]
                                            } else {
                                                date = res.data.year.split("-")
                                                this.obj.year_construction = date[0]
                                            }
                                            this.error.date = true
                                        } else {
                                            this.error.date = false

                                        }
                                        this.knumber = 4
                                        if (!this.error.date) {
                                            this.knumber = 3
                                        }
                                        this.loading = false
                                    }
                                })
                                .catch(e => { 
                                    this.loading = false
                                    this.knumber = 8
                                })
                        }

                    })
                    .catch(e => { 
                        this.knumber = 7
                        this.loading = false
                    })

            }
            if (this.obj.cadastral_number.length == 0) {
                this.knumber = 0

            }
        },
        GoAdd(){
            this.knumber=6
            if(this.NewCadasterInfo.is_changed){
                this.obj.cadastral_number=this.NewCadasterInfo.cad_num
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.bg10 {
    background: rgb(2, 177, 2);
}

.demo-ruleForm {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}

.actions {
    margin-top: 40px;

    .el-button {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }

    .primary {
        display: flex;
        justify-content: space-between;

        .el-button {
            width: calc(50% - 7.5px);
        }
    }
}

 

.title-second-header {
   font-size: 18px;
   font-weight: 600;
   color: #43ABBA !important;
}



.map-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
</style>
